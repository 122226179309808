import React from "react"
import styled from "styled-components"
import Footer from "../sections/Footer"
import { Title1, Title3, Title4, Text } from "../assets/primitives"
import { Container, Row, Col } from "react-grid-system"
import Layout from "../components/layout"

const appConsentForm20190509 = () => (
  <Layout showCookieBanner={false}>
    <ContainerExt>
      <Row>
        <Col xs={12}>
          <Title1>Einwilligungserklärung BRIVE-App</Title1>

          <Text>
            Ich willige ein, dass BRIVE zur Bewertung meines Fahrverhaltens die
            in Ziffer 3 der Nutzungsbedingungen dargestellten
            Verarbeitungsvorgänge vornehmen darf. Insbesondere darf BRIVE über
            die BRIVE-App sämtliche Informationen der Hardwarekomponenten meines
            mobilen Endgerätes GPS-Modul, Beschleunigungssensor, Gyroskop und
            Magnetometer erfassen und in den Kategorien Anfahrverhalten,
            Bremsverhalten, Geschwindigkeit, Handynutzung, Kurvenverhalten und
            Verbrauch aus- und bewerten. BRIVE ist berechtigt, diese Bewertung
            entweder einzeln oder mit anderen Arbeitnehmern zu kombinieren und
            als Gesamtauswertung bereitzustellen. Auf Basis meines erfassten
            Fahrverhaltens ermittelt BRIVE einen Bonuswert und teilt diesen
            meinem Arbeitgeber oder von diesem eingeschaltete Dritte für die
            Ausschüttung des Bonus an mich mit. Weiterhin stimme ich zu, dass
            Rückschlüsse auf meine Person und das zu mir erhobene Fahrverhalten
            in Einzelfällen aus der Gesamtauswertung und grundsätzlich aus dem
            meinem Arbeitgeber mitgeteilten Bonusbetrag möglich sind.
          </Text>
          <Text>
            BRIVE setzt Tools von Drittanbietern ein, um die Nutzung der App
            auszuwerten deren Funktion zu verbessern. Ich bin mit der damit
            verbunden Datenverarbeitung, so wie sie in der Datenschutzerklärung
            dargestellt ist, einverstanden.
          </Text>
          <Text>
            Sie können diese Einwilligung jederzeit mit Wirkung für die Zukunft
            unter{" "}
            <a className="dark" href="mailto:info@brive.io">
              info@brive.io
            </a>{" "}
            widerrufen. Der Widerruf der Einwilligung führt automatisch zur
            Beendigung der Nutzungsvereinbarung mit BRIVE. Gleichzeitig können
            Sie die Erfassung Ihres Fahrverhaltens jederzeit über die
            Einstellungen der BRIVE-App deaktivieren. Im Rahmen der
            Bonusausschüttung können dann aber immer noch Daten verarbeitet
            werden, z.B. die Information, dass für Sie im Auswertungszeitraum
            kein Bonus anfällt.
          </Text>
          <Text>
            Der Widerruf der Einwilligung in den Einsatz von Analyse- und
            Tracking-Tools von Dritten führt nur dazu, dass diese nicht mehr
            eingesetzt werden, die Nutzung von BRIVE bleibt weiterhin möglich.{" "}
          </Text>
          <Text>
            Weitere Informationen zur Datenverarbeitung bei BRIVE, insbesondere
            zu den geltenden Rechtsgrundlagen, der Speicherdauer sowie zu Ihren
            Rechten finden Sie in unserer{" "}
            <a className="dark" href="/app-privacypolicy-2019-05-09/">
              Datenschutzerklärung
            </a>
            .
          </Text>
        </Col>
      </Row>
    </ContainerExt>
  </Layout>
)

export default appConsentForm20190509

const ContainerExt = styled(Container)`
  padding: 3rem 0;
`
